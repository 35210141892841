/** @format */

import React from "react";

const StartUp = () => {
  return (
    <div className='Startup-section bg-dark py-5'>
      <div className='container'>
        <h2 className='text-white text-uppercase'>Find your startup’s</h2>
        <div className='d-flex'>
          <h4 className='color-5cffe4 text-uppercase'>Chosen One</h4>
          <img
            src='assets/badge-in-24-hrs.svg'
            className='img-fluid'
            alt=''
          />
        </div>
        <div className='row pt-3'>
          <div className='d-flex align-items-end'>
            <div className='d-flex align-items-center'>
              <img
                src='assets/death.svg'
                className='img-fluid w-25 ' 
                alt=''
              />
              <div className='text-size'>
                <p className='text-white'>
                  you find yourself treacherously close to the valley of product
                  death
                </p>
              </div>
            </div>
            <img
              src='assets/dots-1.svg'
              className='img-fluid d-none d-md-block'
              alt=''
            />
          </div>
        </div>
        <div className='row mt-3'>
          <div className='d-flex align-items-center justify-content-center resume-sec-part'>
            <img
              src='assets/dots-2.svg'
              className=' d-none d-md-block img-fluid'
              alt=''
            />
            <div className='d-flex align-items-center'>
              <img
                src='assets/icon-way-step-2.svg'
                className='img-fluid'
                alt=''
              />
              <div className='text-size'>
                <p className='text-white'>
                  confess your product needs by chat on Madmonkeys.ca
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='row pt-3'>
          <div className='d-flex align-items-end'>
            <div className='d-flex align-align-items-center'>
              <img
                src='assets/icon-way-step-3.svg'
                className='img-fluid w-25'
                alt=''
              />
              <div className='text-size'>
                <p className='text-white'>
                  A benevolent Mad Monkey account manager reviews our secret
                  sect of devs
                </p>
              </div>
            </div>
            <img
              src='assets/dots-3.svg'
              className=' d-none d-md-block img-fluid'
              alt=''
            />
          </div>
        </div>
        <div className='row mt-3'>
          <div className='d-flex align-items-center justify-content-center resume-sec-part'>
            <img
              src='assets/dots-2.svg'
              className=' d-none d-md-block img-fluid'
              alt=''
            />
            <div className='d-flex align-items-center'>
              <img
                src='assets/icon-way-step-4.svg'
                className='img-fluid w-50'
                alt=''
              />
              <div className='text-size'>
                <p className='text-white'>
                  Your Miraculous Match developer is named and offered to you
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='row pt-3'>
          <div className='d-flex align-items-center'>
            <img
              src='assets/iteration.svg'
              className='img-fluid  ' width={125}
              alt=''
            />
            <div className='text-size'>
              <p className='text-white'>
                unto the world is born the greatest iteration of your product
              </p>
            </div>
          </div>
        </div>

        <div className='content-btn my-2 my-md-5'>
          <a href='/' className='custom-btn text-decoration-none'>
            Match me with a dev
          </a>
        </div>
      </div>
    </div>
  );
};

export default StartUp;
