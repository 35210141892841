import React from 'react'

const Miraculous = () => {
    return (
        <div className='Miraculous-section pb-5 bg-dark'>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className='text-center'>
                            <img src="assets/time.svg" className='img-fluid   mb-4' width={125} alt="" />
                            <h4>Miraculous 24-hr Match</h4>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className='text-center'>
                            <img src="assets/Matching.svg" className='img-fluid mb-4  '  width={185} alt="" />
                            <h4>MCustom Assisted Matching</h4>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className='text-center'>
                            <img src="assets/Zero-Risk.svg" className='img-fluid mb-4' width={205} alt="" />
                            <h4>Zero-Risk Replacement Guarantee</h4>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Miraculous