/** @format */

import React, { useState } from "react";

const Apply = () => {
    const [to, setTo] = useState("");
    const [subject, setSubject] = useState("");
    const [text, setText] = useState("");
    
  const handleSendEmail = async () => {
    try {
      const data = await ({
        from: "hassanjaved836@gmail.com",
        to: "hassan.relymer@gmail.com",
        subject: "Hello world",
        text: "yessssssss", // Use the EmailTemplate component with the firstName prop
      });

      console.log(data); // You can handle the response data as per your requirement
    } catch (error) {
      console.error(error); // Handle errors if any
    }
  };

  return (
    <>
      <div className='container apply-section bg-dark py-5'>
        <div className='row'>
          <h2 className='text-white text-uppercase m-auto '>
            Kick start Your Digital Journey Today....
          </h2>
          <div className='mb-3 mt-5'>
            <input
              className='form-control  m-auto '
              type='text'
              name='username'
              placeholder='Full Name'
              aria-label='username'
            />
          </div>
          <div className='mb-3 '>
            <input
              className='form-control  m-auto'
              type='email'
              name='email'
              placeholder='Email'
              value={to} onChange={(e) => setTo(e.target.value)}
              aria-label='email'
            />
          </div>
          <div className='mb-3'>
            <input
              className='form-control  m-auto'
              type='text'
              name='subject'
              value={subject} onChange={(e) => setSubject(e.target.value)}
              placeholder='Apply For'
              aria-label='Subject'
            />
          </div>
          <div className='mb-3'>
            <textarea
              className='form-control  m-auto'
              name='Describe Yourself'
              placeholder='Describe Yourself'
              aria-label='describe yourself'
              value={text} onChange={(e) => setText(e.target.value)}
              rows={8}
            />
          </div>
          <div className=' text-center '>
            <button
              className='btn btn-subscribe'
              type='submit'
              onClick={handleSendEmail}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Apply;
