/** @format */

import React, { useEffect, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { BsChatTextFill } from "react-icons/bs";
import ChatBot from "react-simple-chatbot";

const FloatingIcon = styled.div`
  position: fixed;
  bottom: 40px;
  right: 30px;
  background-color: #dcff02;
  color: #000;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
`;

const ChatWidgetContainer = styled.div`
  position: fixed;
  bottom: 80px;
  right: 30px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  background: #212529;
  z-index: 998;
`;

const MessagesContainer = styled.div`
  padding: 10px;
  max-height: 300px;
  overflow-y: auto;
`;

const Message = styled.div`
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 5px;
  font-family: "Source Code Pro";

  background-color: ${props => (props.sender === "user" ? "#dcff02" : "#fff")};
  float: ${props => (props.sender === "user" ? "right" : "left")};
  max-width: 80%;
  clear: both; /* Clear the float to avoid messages appearing on the same line */
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #212529;
`;

const Input = styled.input`
  flex-grow: 1;
  padding: 8px;
  border: none;
  border-radius: 5px;
  font-family: "Source Code Pro";
  outline: none !important;
  transition: 0.4s ease all;
  box-shadow: none !important;
`;

const SendButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  background-color: #dcff02;
  color: #000;
  cursor: pointer;
`;

const ChatWidget = () => {
  const [showChat, setShowChat] = useState(false); // To toggle the chat widget visibility
  const [messages, setMessages] = useState([{ text: "Hi, there", user: 1 }]);
  const [newMessage, setNewMessage] = useState("");

  const handleSendMessage = () => {
    if (newMessage.trim() === "") return;
    setMessages([...messages, { text: newMessage, sender: "user" }]);
    setNewMessage("");
    // Handle sending the message to the server or chatbot
  };
  // useEffect(() => {
  //   fetchMessages();
  // }, []);

  // const fetchMessages = async () => {
  //   try {
  //     const response = await fetch("http://localhost:3001/getMessages");
  //     const data = await response.json();
  //     setMessages(data);
  //     console.log("message: ", data);
  //   }  catch (error) {
  //     console.error(error); // Handle errors if any
  //   }
  // };

  // all available props
  // Creating our own theme
  const theme = {
    background: "#212529",
    headerBgColor: "#dcff02",
    headerFontSize: "20px",
    botBubbleColor: "#c58fff",
    headerFontColor: "#000",
    botFontColor: "white",
    userBubbleColor: "#a9ff03",
    userFontColor: "#000",
  };

  // Set some properties of the bot
  const config = {
    botAvatar: "assets/ava.png",
    floating: true,
  };


  const steps = [
    { id: "1", message: "Welcome to our chatbot!", trigger: "2" },
    {
      id: "2",
      message: "How can I assist you today?",
      trigger: "user-response",
    },
    {
      id: "user-response",
      user: true,
      trigger: "response-message",
    },
    {
      id: "response-message",
      message:
        "Thank you for your message! Our team will get back to you shortly.",
      end: false,
    },
    
  ];
  return (
    <>
      <ThemeProvider theme={theme}>
        {/* {showChat ? (
          <ChatWidgetContainer>
            <MessagesContainer>
              {messages.map((message, index) => (
                <Message key={index} sender={message.sender}>
                  {message.text}
                </Message>
              ))}
            </MessagesContainer>
            <InputContainer>
              <Input
                type='text'
                placeholder='Type your message...'
                value={newMessage}
                onChange={e => setNewMessage(e.target.value)}
              />
              <SendButton onClick={handleSendMessage}>Send</SendButton>
            </InputContainer>
          </ChatWidgetContainer>
        ) : (
          <FloatingIcon onClick={() => setShowChat(true)}>
            <BsChatTextFill size={35} />
          </FloatingIcon>
        )} */}
        <ChatBot
          // This appears as the header
          // text for the chat bot
          headerTitle='Mad Monkey'
          steps={steps}
          {...config}
        />
      </ThemeProvider>
    </>
  );
};

export default ChatWidget;
