/** @format */

import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div
      className='d-flex align-items-center text-center text-light'
      style={{ height: "80vh" }}>
      <div className='w-75 m-auto'>
        <p className='h1 fw-bold mb-3' align='center'>
          404
        </p>
        <p align='center' className='h5 fw-bold mb-3'>
          Sorry! Page Not Found
        </p>

        <div align='center'>
          <Link to='/' className='text-decoration-none  text-black'>
            <span type='button' className='custom-btn'>
              GO TO HOME PAGE
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
