/** @format */

import React from "react";

const Testemonial3 = () => {
  return (
    <div className='Testemonial3-section py-5 bg-dark'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-7'>
            <div className='testemonial1-content-sec'>
              <div className='testemonial-sec'>
                <p>
                  we’ve had a full-time developer with Mad Monkey for over 6mos
                  and he’s definitely part of the team now. he helped develop
                  our api, web and mobile app. definitely been a great
                  experience and we’ll continue working with him.
                </p>
              </div>
              <p className='rotate-text'>// Dustin, CEO</p>
            </div>
            <div className='testemonial1-icon'>
              <img
                src='assets/testimonial-face-3.svg'
                className='img-fluid'
                alt=''
              />
            </div>
          </div>

          <div className='col-md-5'>
            <div className='testemonial2-content-sec'>
              <div className='testemonial-sec'>
                <p>
                  i consistently get great service from the team at Mad Monkey.
                  they are prompt and save me a ton of time. been using for
                  years
                </p>
              </div>
              <p className='rotate-text'>// tim robbins</p>
            </div>
            <div className='testemonial2-icon'>
              <img
                src='assets/testimonial-face-4.svg'
                className='img-fluid'
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testemonial3;
